// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-publications-tsx": () => import("./../../../src/pages/publications.tsx" /* webpackChunkName: "component---src-pages-publications-tsx" */),
  "component---src-pages-research-tsx": () => import("./../../../src/pages/research.tsx" /* webpackChunkName: "component---src-pages-research-tsx" */),
  "component---src-pages-transition-tsx": () => import("./../../../src/pages/transition.tsx" /* webpackChunkName: "component---src-pages-transition-tsx" */),
  "component---src-templates-news-and-thoughts-tsx": () => import("./../../../src/templates/news_and_thoughts.tsx" /* webpackChunkName: "component---src-templates-news-and-thoughts-tsx" */),
  "component---src-templates-research-article-tsx": () => import("./../../../src/templates/research_article.tsx" /* webpackChunkName: "component---src-templates-research-article-tsx" */),
  "component---src-templates-thought-article-tsx": () => import("./../../../src/templates/thought_article.tsx" /* webpackChunkName: "component---src-templates-thought-article-tsx" */)
}

